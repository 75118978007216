import React from "react";

import { connect } from "react-redux";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { setStorage } from "../../utils/storage";
import { Loading } from "../Loader";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;
class SSAuth extends React.Component {
  state = {
    isSubmitting: false,
    error: {},
    password: "",
  };

  componentDidMount() {
    this.onSSAuth();
  }

  onSSAuth = async () => {
    const { token, accountType, email } = this.props;

    if (!token)
      return toastMessage(
        "error",
        "Couldn't find token, please make sure token is specified in the url"
      );
    if (!accountType)
      return toastMessage(
        "error",
        "Couldn't find accountType, please make sure account type is specified in the url"
      );

    this.setState({ isSubmitting: true });

    const options = {
      method: "POST",
      url: `${API_URL}/authentication/${accountType}`,
      data: {
        token,
      },
    };

    axios(options)
      .then((data) => {
        this.setState({ isSubmitting: false });

        if (data.data.status === "error")
          return toastMessage("error", data.data.message);

        let userInfo = {
          ...data.data,
          token: data?.data?.token,
          email: data?.data?.email,
          role: accountType,
          institution: data?.data?.institution?.toUpperCase(),
        };

        if (userInfo.ippisUserData) {
          userInfo["firstName"] = userInfo.ippisUserData.firstName;
          userInfo["lastName"] = userInfo.ippisUserData.lastName;

          userInfo.displayName =
            userInfo.ippisUserData.firstName +
            " " +
            userInfo.ippisUserData.lastName;
        } else if (!data.data.displayName) {
          userInfo.displayName = data?.data?.email?.split("@")[0];
        }

        this.onSuccess(userInfo);
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });

        toastMessage(
          "error",
          language[this.props.defaultLanguage].wrong_email_password
        );
      });
  };

  onSuccess = async (data) => {
    await setStorage(data);

    const { accountType } = this.props;

    if (accountType === "user")
      return (window.location.href = "/public-servant/my requests");
    if (accountType === "admin")
      return (window.location.href = "/dashboard/home");

    toastMessage(`Seems we don't allow account type specified: ${accountType}`);
  };

  render() {
    return (
      <div className="main-ssauth">
        {this.state.isSubmitting && <Loading className="loader-fullscreen" />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SSAuth);
