import React from "react";
import AuthFormLayout from "../../Layout/AuthFormLayout";
import SSAuth from "../../components/ssAuth/SSAuth";
import { withRouter } from "../../utils/withRouter";

class SSAuthScreen extends React.Component {
  render() {
    console.log("I am here", this.props.routeParams);

    return (
      <AuthFormLayout>
        <SSAuth
          token={this.props.routeParams.token}
          accountType={this.props.routeParams.accountType}
          email={this.props.routeParams.email}
        />
      </AuthFormLayout>
    );
  }
}

export default withRouter(SSAuthScreen);
